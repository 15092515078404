import React from 'react';
import avatar from '../images/cat.jpg';
import {
  Container, Card, Row, Col, Image, ProgressBar, Button, Modal, Form, Dropdown, InputGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

import api from '../services/api';

class MyGames extends React.Component {
  constructor() {
    super();

    this.state = {
      loadingData: true,
      playing: [],
      dm: [],
      npc: [],
      expTable: [],
      games: [],
      characters: [],
      newGameType: 1,
      newGameTime: moment().format('YYYY-MM-DDTHH:mm'),
      newGameId: null,
      newCharacterId: null,
      newGameDurationHours: 0,
      newGameDurationMinutes: 0,
    };

    this.getMyGames = this.getMyGames.bind(this);
    this.createNewGameRecord = this.createNewGameRecord.bind(this);
  }

  async getMyGames() {
    const [playing] = await api.get('/player/getMyGames');
    this.setState({ playing: playing });
    const [dm] = await api.get('/dm/getMyDMGames');
    this.setState({ dm: dm });
    const [npc] = await api.get('/dm/getMyNPCGames');
    this.setState({ npc: npc });
  }

  async componentDidMount() {
    const [games] = await api.get('/game/getAllGames');
    this.setState({
      games: games,
      newGameId: games[0].gameId,
    });
    const [expTable] = await api.get('/general/getExpTable');
    this.setState({ expTable: expTable, loadingData: false });
    this.getMyGames();
  }

  async getCharactersForGame() {
    const [characters] = await api.get('/game/getCharactersForGame', { gameId: this.state.newGameId });
    this.setState({
      characters: characters,
      newCharacterId: characters[0].gameId,
    });
  }

  calculateLevel(hours) {
    let currentLevel;
    this.state.expTable.map((thisLevel) => {
      if (thisLevel.hoursRequired < hours) {
        currentLevel = thisLevel;
      }
      return true;
    });
    return currentLevel;
  }

  calculateExpProgress(currentLevel, hours) {
    const nextLevel = this.state.expTable[this.state.expTable.indexOf(currentLevel) + 1];
    const thisLevelProgress = parseFloat(hours - currentLevel.hoursRequired);
    const thisLevelNetRequirement = parseFloat(nextLevel.hoursRequired - currentLevel.hoursRequired);
    return [(parseFloat(thisLevelProgress / thisLevelNetRequirement) * 100).toFixed(2), nextLevel.hoursRequired];
  }

  async createNewGameRecord() {
    // Calculate the game length into minutes
    const newGameDuration = parseInt(this.state.newGameDurationHours) * 60 + parseInt(this.state.newGameDurationMinutes);
    let endpoint;
    switch (this.state.newGameType) {
      case 1:
        endpoint = '/player/createGame';
        break;
      case 2:
        endpoint = '/dm/createGame';
        break;
      case 3:
        endpoint = '/npc/createGame';
        break;
      default:
        endpoint = '/player/createGame';
    }
    const result = await api.post(endpoint, {
      newGameTime: moment(this.state.newGameTime).format(),
      newGameId: this.state.newGameId,
      newCharacterId: this.state.newCharacterId,
      newGameDuration,
    });
    console.log(result);
    if (result !== []) {
      this.setState({ creating: false });
      this.getMyGames();
    }
  }

  render() {
    if (this.state.loadingData) {
      return <div>Nope.</div>;
    }

    const newGameTypeLabel = [null, '打本', '开本', ' NPC 扮演'];

    const totalPlayerHours = this.state.playing.length ? this.state.playing.reduce((a, b) => a + b.duration / 60, 0) : 0;
    const playerLevel = this.calculateLevel(totalPlayerHours);
    const [playerExpProgress, nextPlayerExpRequirement] = this.calculateExpProgress(playerLevel, totalPlayerHours);
    const totalDMHours = this.state.dm.length ? this.state.dm.reduce((a, b) => a + b.duration / 60, 0) : 0;
    const dmLevel = this.calculateLevel(totalDMHours);
    const [dmExpProgress, nextDMExpRequirement] = this.calculateExpProgress(dmLevel, totalDMHours);
    const totalNPCHours = this.state.npc.length ? this.state.npc.reduce((a, b) => a + b.duration / 60, 0) : 0;
    return (
      <Container className="mb-3 mt-2">
        <div className="d-flex align-items-center">
          <Image className="avatar me-2" src={avatar} />
          <span className="flex-grow-1">
            <span className="lead">🔥 🍐</span>
            <br />
            玩家 Lv. {playerLevel.levelId} | 【{playerLevel.levelName}玩家】
            <span className="float-end">
              {totalPlayerHours.toFixed(2)}小时/{nextPlayerExpRequirement}小时 ({playerExpProgress}%)
            </span>
            <ProgressBar variant="success" animated className="expProgress" now={playerExpProgress} />
            DM Lv. {dmLevel.levelId} | 【{dmLevel.levelName}DM】
            <span className="float-end">
              {totalDMHours.toFixed(2)}小时/{nextDMExpRequirement}小时 ({dmExpProgress}%)
            </span>
            <ProgressBar variant="warning" animated className="expProgress" now={dmExpProgress} />
          </span>
        </div>
        <hr className="mt-2 mb-1" />
        <Row>
          <Col>
            <p className="m-1">
              <span className="float-end">
                <Button onClick={() => this.setState({creating: true, newGameType: 1})}>又打本了？</Button>
              </span>
              <span className="h5">
                我的打本历史
              </span>
              <br />
              <span>
                {this.state.playing.length}个本 | 总计{totalPlayerHours.toFixed(2)}小时
              </span>
            </p>
          </Col>
        </Row>
        <Row>
          {this.state.playing.length > 0 && (
            this.state.playing.map((game) => (
              <Col xs={6} md={4} lg={3} xl={2} className="mt-1" key={game.playerGameId}>
                <Card>
                  <Card.Header className="p-1">
                    <Link to={`/gameDetail/${game.gameId}`}>《{game.gameName}》</Link>
                    <br />
                    <small className="float-end">
                      {game.startTime}
                    </small>
                  </Card.Header>
                  <Card.Body className="text-center p-1">
                    <span className="float-start">
                      {(game.duration / 60).toFixed(2)}小时
                    </span>
                    {game.numberOfCharacters}人本
                    <span className="float-end">
                      {game.characterName}
                    </span>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>
        <hr className="mt-2 mb-1" />
        <Row>
          <Col>
            <p className="m-1">
              <span className="float-end">
                <Button onClick={() => this.setState({creating: true, newGameType: 2})}>又开本了？</Button>
              </span>
              <span className="h5">
                DM 开本历史
              </span>
              <br />
              <span>
                {this.state.dm.length}个本 | 总计{totalDMHours.toFixed(2)}小时
              </span>
            </p>
          </Col>
        </Row>
        <Row>
          {this.state.dm.length > 0 && (
            this.state.dm.map((game) => (
              <Col xs={6} md={4} lg={3} xl={2} className="mt-1" key={game.dmGameId}>
                <Card>
                  <Card.Header className="p-1">
                    <Link to={`/gameDetail/${game.gameId}`}>《{game.gameName}》</Link>
                    <br />
                    <small className="float-end">
                      {game.startTime}
                    </small>
                  </Card.Header>
                  <Card.Body className="text-center p-1">
                    <span className="float-start">
                      {(game.duration / 60).toFixed(2)}小时
                    </span>
                    <span className="float-end">
                      {game.numberOfCharacters}人本
                    </span>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>
        <hr className="mt-2 mb-1" />
        <Row>
          <Col>
            <p className="m-1">
              <span className="h5">
                NPC 参演历史
              </span>
              <br />
              <span>
                {this.state.npc.length}个本 | 总计{totalNPCHours.toFixed(2)}小时
              </span>
            </p>
          </Col>
        </Row>
        <Row>
          {this.state.npc.length > 0 && (
            this.state.npc.map((game) => (
              <Col xs={6} md={4} lg={3} xl={2} className="mt-1" key={game.npcGameId}>
                <Card>
                  <Card.Header className="p-1">
                    <Link to={`/gameDetail/${game.gameId}`}>《{game.gameName}》</Link>
                    <br />
                    <small className="float-end">
                      {game.startTime}
                    </small>
                  </Card.Header>
                  <Card.Body className="text-center p-1">
                    <span className="float-start">
                      {(game.duration / 60).toFixed(2)}小时
                    </span>
                    {game.numberOfCharacters}人本
                    <span className="float-end">
                      {game.characterName}
                    </span>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>
        <Modal show={this.state.creating} onHide={() => this.setState({creating: false})} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>添加{newGameTypeLabel[this.state.newGameType]}记录</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{ newGameTypeLabel[this.state.newGameType] }时间</Form.Label>
                    <Form.Control type="datetime-local" value={this.state.newGameTime} onChange={(e) => this.setState({newGameTime: e.target.value})} />
                    <Form.Text className="text-muted">剧本开始的日期和时间</Form.Text>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>剧本</Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {this.state.games.find((game) => game.gameId === this.state.newGameId).gameName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item></Dropdown.Item>
                        {this.state.games.length > 0 && this.state.games.map((game) => (
                          <Dropdown.Item key={game.gameId} onClick={() => {
                            this.setState({ newGameId: game.gameId }, this.getCharactersForGame);
                          }}>{game.gameName}</Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Col>
                {this.state.newGameType !== 2 && (
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>角色</Form.Label>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {this.state.newCharacterId && this.state.characters.length && this.state.characters.find((character) => character.characterId === this.state.newCharacterId).characterName}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {this.state.characters.length > 0 && this.state.characters.map((character) => (
                            <Dropdown.Item key={character.characterId} onClick={() => this.setState({ newCharacterId: character.characterId })}>{character.characterName}</Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{newGameTypeLabel[this.state.newGameType]}时长</Form.Label>
                    <InputGroup>
                      <Form.Control type="number" step={1} min={0} max={50} value={this.state.newGameDurationHours} onChange={(e) => this.setState({ newGameDurationHours: e.target.value })} />
                      <InputGroup.Text>小时</InputGroup.Text>
                      <Form.Control type="number" step={1} min={0} max={60} value={this.state.newGameDurationMinutes} onChange={(e) => this.setState({newGameDurationMinutes: e.target.value})} />
                      <InputGroup.Text>分钟</InputGroup.Text>
                    </InputGroup>
                    <Form.Text className="text-muted">剧本持续了多久</Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer className="text-end">
            <Button variant="link" onClick={() => this.setState({ creating: false })}>算了</Button>
            <Button onClick={this.createNewGameRecord}>添加</Button>
          </Modal.Footer>
        </Modal>
      </Container >
    );
  }
}

export default MyGames;

import React from 'react';
import { Button } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import PropTypes from 'prop-types';

class ActionButton extends React.Component {
  constructor() {
    super();
    this.enterInProgressState = this.enterInProgressState.bind(this);
    this.state = {
      loading: false,
      unmounted: false,
    };
  }

  componentWillUnmount() {
    this.setState({ unmounted: true });
  }

  async enterInProgressState(e) {
    await this.setState({
      loading: true,
      disabled: true,
    });

    await this.props.action(e).catch(() => {});

    // Only if this Action Button is still mounted by the parent component
    if (!this.state.unmounted) {
      this.setState({
        loading: null,
        disabled: false,
      });
    }
  }

  render() {
    return (
      <Button
        type={this.props.submit ? 'submit' : 'button'}
        variant={this.props.buttonType}
        size={this.props.size}
        onClick={this.enterInProgressState}
        className={this.props.className}
        disabled={this.props.preDisabled || this.state.disabled}
      >
        {
          this.state.loading
            ? <FaSpinner className="spinning" />
            : (
              <span>
                {this.props.buttonIcon && (
                  <span>
                    {this.props.buttonIcon}
                    &nbsp;
                  </span>
                )}
                {this.props.buttonLabel}
              </span>
            )
        }
      </Button>
    );
  }
}

ActionButton.propTypes = {
  buttonType: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  buttonIcon: PropTypes.object,
  size: PropTypes.string,
  className: PropTypes.string,
  action: PropTypes.func.isRequired,
  preDisabled: PropTypes.bool,
  submit: PropTypes.bool,
};

ActionButton.defaultProps = {
  buttonIcon: null,
  buttonType: 'primary',
  preDisabled: false,
  submit: false,
  size: null,
  className: null,
};

export default ActionButton;

import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Card, Badge,
} from 'react-bootstrap';

import { useParams } from 'react-router-dom';

import api from '../../services/api';

const GameDetail = () => {
  const [gameName, setGameName] = useState('');
  const [numberOfCharacters, setNumberOfCharacters] = useState(0);
  const [characters, setCharacters] = useState([]);

  const params = useParams();

  useEffect(() => {
    async function getGameDetail() {
      const [gameData] = await api.get('/game/getGameDetail', { gameId: params.gameId });
      if (gameData) {
        setGameName(gameData.gameName);
        setNumberOfCharacters(gameData.numberOfCharacters);
        console.log(typeof gameData.characters);
        setCharacters(gameData.characters);
      }
    }
    getGameDetail();
  }, [params]);

  return (
    <Container className="mb-3 mt-2">
      <Row>
        <Col>
          <h1>
             《{gameName}》
          </h1>
          <p>{numberOfCharacters}人本</p>
          <Card>
            <Card.Body>
              <h3>人物</h3>
              <p>
                {characters.length > 0 && characters.map((character) => (
                  <Badge>{character}</Badge>
                ))}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default GameDetail;

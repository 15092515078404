import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Card, Badge, Form, Dropdown, Button,
} from 'react-bootstrap';

import { useParams } from 'react-router-dom';

import api from '../../services/api';

const GameDetail = () => {
  const [gameName, setGameName] = useState('');
  const [numberOfCharacters, setNumberOfCharacters] = useState(0);
  const [characters, setCharacters] = useState([]);
  const [newCharacterName, setNewCharacterName] = useState('');
  const [newCharacterGender, setNewCharacterGender] = useState(1);

  const params = useParams();

  const getGameDetail = async () => {
    const [gameData] = await api.get('/game/getGameDetail', { gameId: params.gameId });
    if (gameData) {
      setGameName(gameData.gameName);
      setNumberOfCharacters(gameData.numberOfCharacters);
      setCharacters(gameData.characters);
    }
  };

  useEffect(() => {
    getGameDetail();
  }, [params]);

  const createNewCharacter = async () => {
    await api.post('/game/createNewCharacter', {
      gameId: params.gameId,
      newCharacterName,
      newCharacterGender,
    });
    getGameDetail();
    setNewCharacterName('');
  };

  return (
    <Container className="mb-3 mt-2">
      <Row>
        <Col>
          <h1>
            《{gameName}》
            <small><Badge>{numberOfCharacters}人本</Badge></small>
          </h1>
          <hr />
          <h3>角色</h3>
          <Row>
            {characters.length > 0 && characters.map((character) => (
              <Col xs={6} sm={4} md={3} lg={2}>
                <Card>
                  <Card.Body>
                    <h4>{character.characterName}</h4>
                    <Badge>{character.gender === 1 ? '男' : '女'}</Badge>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h3>新增角色</h3>
          <Row>
            <Col>
              <Form.Group controlId="newCharacterName" as={Col}>
                <Form.Label>角色姓名</Form.Label>
                <Form.Control value={newCharacterName} onChange={(e) => setNewCharacterName(e.target.value)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="newCharacterName" as={Col}>
                <Form.Label>性别</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {newCharacterGender === 1 ? '男' : '女'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setNewCharacterGender(1)}>男</Dropdown.Item>
                    <Dropdown.Item onClick={() => setNewCharacterGender(2)}>女</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Col>
          </Row>
          <Button className="mt-2" onClick={createNewCharacter}>添加</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default GameDetail;

import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Card, Badge, Form, Dropdown, Button,
} from 'react-bootstrap';

import { useParams, Link } from 'react-router-dom';

import api from '../../services/api';

const GameDetail = () => {
  const [allGames, setAllGames] = useState('');
  const [newGameName, setNewGameName] = useState('');
  const [newGameNumberOfCharacters, setNewGameNumberOfCharacters] = useState(1);

  const params = useParams();

  const getAllGames = async () => {
    const [gameData] = await api.get('/game/getAllGames', { gameId: params.gameId });
    if (gameData) {
      setAllGames(gameData);
    }
  };

  useEffect(() => {
    getAllGames();
  }, [params]);

  const createNewGame = async () => {
    await api.post('/game/createNewGame', {
      newGameName,
      newGameNumberOfCharacters,
    });
    getAllGames();
    setNewGameName('');
  };

  return (
    <Container className="mb-3 mt-2">
      <Row>
        <Col>
          <h1>剧本列表</h1>
          <Row>
            {allGames.length > 0 && allGames.map((game) => (
              <Col xs={6} sm={4} key={game.gameId}>
                <Card className="mb-2">
                  <Card.Body>
                    <h4><Link to={`/gameDetail/${game.gameId}`}>《{game.gameName}》</Link></h4>
                    <Badge>{game.numberOfCharacters}人本</Badge>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <hr />
          <h3>新增剧本</h3>
          <Row>
            <Col>
              <Form.Group controlId="newGameName" as={Col}>
                <Form.Label>剧本名</Form.Label>
                <Form.Control value={newGameName} onChange={(e) => setNewGameName(e.target.value)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="newGameNumberOfCharacters" as={Col}>
                <Form.Label>角色人数</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {newGameNumberOfCharacters}人本
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setNewGameNumberOfCharacters(4)}>4人本</Dropdown.Item>
                    <Dropdown.Item onClick={() => setNewGameNumberOfCharacters(5)}>5人本</Dropdown.Item>
                    <Dropdown.Item onClick={() => setNewGameNumberOfCharacters(6)}>6人本</Dropdown.Item>
                    <Dropdown.Item onClick={() => setNewGameNumberOfCharacters(7)}>7人本</Dropdown.Item>
                    <Dropdown.Item onClick={() => setNewGameNumberOfCharacters(8)}>8人本</Dropdown.Item>
                    <Dropdown.Item onClick={() => setNewGameNumberOfCharacters(9)}>9人本</Dropdown.Item>
                    <Dropdown.Item onClick={() => setNewGameNumberOfCharacters(10)}>10人本</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Col>
          </Row>
          <Button className="mt-2" onClick={createNewGame}>添加</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default GameDetail;

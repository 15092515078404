import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Card, Button, Form,
} from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

import ActionButton from '../../shared/fragments/ActionButton';

import api from '../../services/api';

class Account extends React.PureComponent {
  constructor() {
    super();
    this.getAccount = this.getAccount.bind(this);
    this.logout = this.logout.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
    this.state = {
      email: '',
      name: '',
      registrationTime: 0,
      editing: false,
      newName: '',
      newNameError: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    if (this.props.location && this.props.location.hash === '#billing') {
      this.setState({ highlightBilling: true });
    }
    this.getAccount();
  }

  async getAccount() {
    const [data] = await api.get('/account/getAccount');
    this.props.setIsSignalCaller(!!data.signalCaller);
    return this.setState({
      email: data.email,
      name: data.name,
      bio: data.bio,
      newName: data.name,
      newBio: data.bio,
      registrationTime: data.registrationTime,
    });
  }

  async logout() {
    await api.post('/account/logout');
    toast.success('登出成功~');
    // Remember login session token
    return this.props.setLogoutStatus();
  }

  async updateAccount() {
    this.setState({ newNameError: false });
    // Validation
    if (!this.state.newName) {
      return this.setState({ newNameError: true });
    }
    await api.post('/account/updateAccount', {
      newName: this.state.newName,
      newBio: this.state.newBio,
    });
    toast.success('Account updated successfully.');
    this.setState({ editing: false });
    return this.getAccount();
  }

  render() {
    return (
      <>
        <Container>
          <Row>
            <Col>
              <Card>
                <Card.Header>我的剧本杀账号</Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={3} xs={6}>
                      <p>Email</p>
                    </Col>
                    <Col md={9} xs={6}>
                      <p className="text-white">{this.state.email}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} xs={6}>
                      <p>玩家名</p>
                    </Col>
                    <Col md={9} xs={6}>
                      <p className="text-white">
                        {!this.state.editing && this.state.name}
                        {this.state.editing && (
                        <>
                          <Form.Control
                            type="text"
                            isInvalid={this.state.newNameError}
                            value={this.state.newName}
                            onChange={(e) => this.setState({ newName: e.target.value })}
                          />
                          <Form.Control.Feedback type="invalid">必填内容</Form.Control.Feedback>
                        </>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} xs={6}>
                      <p>个人简介</p>
                    </Col>
                    <Col md={9} xs={6}>
                      <p className="text-white descriptionText">
                        {!this.state.editing && this.state.bio}
                        {this.state.editing && (
                        <>
                          <Form.Control
                            as="textarea"
                            value={this.state.newBio}
                            onChange={(e) => this.setState({ newBio: e.target.value })}
                          />
                          <Form.Control.Feedback type="invalid">必填内容</Form.Control.Feedback>
                        </>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} xs={6}>
                      <p>打本始于</p>
                    </Col>
                    <Col md={9} xs={6}>
                      <p className="text-white">
                        {moment.unix(this.state.registrationTime).format('L')}
                        {` (${moment.unix(this.state.registrationTime).fromNow()})`}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {this.state.editing
                        ? (
                          <>
                            <ActionButton
                              buttonLabel="更新资料"
                              action={this.updateAccount}
                            />
                            <Button variant="link" onClick={() => this.setState({ editing: false })}>算了</Button>
                          </>
                        ) : (
                          <Button onClick={() => this.setState({ editing: true })}>更新资料</Button>
                        )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <ActionButton
                buttonType="danger"
                buttonLabel="退出登录"
                action={this.logout}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Account.propTypes = {
  setLogoutStatus: PropTypes.func.isRequired,
};

// Map TCData from Redux to this component
const mapStateToProps = (state) => state;

// Map TCData dispatch methods
const mapDispatchToProps = (dispatch) => ({
  setLogoutStatus: () => {
    dispatch({ type: 'LOGOUT' });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);

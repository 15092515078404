import React from 'react';
import {
  Container, Card, Row, Col, Button, Dropdown, ButtonGroup,
} from 'react-bootstrap';

class MyGames extends React.Component {
  constructor() {
    super();

    this.state = {
      services: [{
        round: 1,
        customers: [
          [{
            name: '刘老根',
            customerType: 3
          }, {
            name: '林子蒜',
            customerType: 4
          }],
          [{
            name: '娜扎尔巴耶夫',
            customerType: 2
          }, {
            name: '班猜',
            customerType: 1
          }]
        ]
      }, {
        round: 2,
        customers: [
          [{
            name: '刘老根',
            customerType: 3
          }, {
            name: '林子蒜',
            customerType: 4
          }],
          [{
            name: '娜扎尔巴耶夫',
            customerType: 2
          }, {
            name: '班猜',
            customerType: 1
          }]
        ]
      }],
      round: 1,
      selectedCharacter: 0,
      characterSkill: false,
      characters: [{
        id: 1,
        name: '31',
        attitude: 3,
        charisma: 2,
        baseTips: 700,
      }, {
        id: 2,
        name: '36',
        attitude: 1,
        skilledAttitude: 3,
        charisma: 3,
        baseTips: 650,
        skilledTips: 950,
      }, {
        id: 3,
        name: '8',
        attitude: 3,
        charisma: 3,
        baseTips: 500,
      }, {
        id: 4,
        name: '18',
        attitude: 1,
        skilledAttitude: 3,
        charisma: 1,
        skilledCharisma: 3,
        baseTips: 850,
      }, {
        id: 5,
        name: '97',
        attitude: 1,
        charisma: 1,
        baseTips: 950,
        skilledTips: 1050,
      }, {
        id: 6,
        name: '66',
        attitude: 3,
        charisma: 2,
        baseTips: 650,
        skilledTips: 0,
      }, {
        id: 7,
        name: '7',
        attitude: 1,
        charisma: 3,
        baseTips: 900,
        skilledTips: 1000,
      }],
      selectedCustomer: 0,
      customers: [{
        id: 1,
        name: '泰国人',
        attitude: 3,
        charisma: 2,
      }, {
        id: 2,
        name: '非洲人',
        attitude: 1,
        charisma: 1,
      }, {
        id: 3,
        name: '东北人',
        attitude: 3,
        charisma: 1,
      }, {
        id: 4,
        name: '香港人',
        attitude: 3,
        charisma: 3,
      }, {
        id: 5,
        name: '欧洲人',
        attitude: 1,
        charisma: 2,
      }, {
        id: 6,
        name: '韩国人',
        attitude: 1,
        charisma: 3,
      }],
      selectedRoom: 0,
      rooms: [{
        id: 1,
        name: '中式房',
        description: '有毒',
      }, {
        id: 2,
        name: '美式房',
        description: '有毒',
      }, {
        id: 3,
        name: '日式房',
        description: '毒被36解了',
      }, {
        id: 4,
        name: '民国房',
        description: '毒被36解了',
      }, {
        id: 5,
        name: '欧式房',
        description: '藏《大力》',
      }, {
        id: 6,
        name: '塔罗房',
        description: '藏《五行》',
      }, {
        id: 7,
        name: '豆袋沙发房',
        description: '藏《三千》',
      }],
    };

    this.calculateTips = this.calculateTips.bind(this);
  }

  async calculateTips() {
  }

  render() {
    const attributes = [null, '下', '中', '上'];
    const currentCustomer = this.state.customers.find((customer) => customer.id === this.state.selectedCustomer);
    const currentCharacter = this.state.characters.find((character) => character.id === this.state.selectedCharacter);
    return (
      <Container className="mb-3 mt-2">
        <Row>
          <Col xs={12} className="mb-3">
            <h2>玩家</h2>
            <ButtonGroup>
              {this.state.characters.map((character) => (
                <Button variant="danger" key={character.id} active={character.id === this.state.selectedCharacter} onClick={() => this.setState({ selectedCharacter: character.id })}>
                  <span className="h3">{character.name}</span><br />
                  服务态度：{attributes[character.attitude]}<br />
                  颜值：{attributes[character.charisma]}<br />
                  基础小费：{character.baseTips}<br />
                  技能小费：{character.skilledTips}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
          <Col xs={12} className="mb-3">
            <h2>玩家技能启动</h2>
            <ButtonGroup>
              <Button variant="danger" active={this.state.characterSkill} onClick={() => this.setState({ characterSkill: true })}>
                <span className="h3">是</span>
              </Button>
              <Button variant="danger" active={!this.state.characterSkill} onClick={() => this.setState({ characterSkill: false })}>
                <span className="h3">否</span>
              </Button>
            </ButtonGroup>
          </Col>
          <Col xs={12} className="mb-3">
            <h2>客人</h2>
            <ButtonGroup>
              {this.state.customers.map((customer) => (
                <Button variant="danger" key={customer.id} active={customer.id === this.state.selectedCustomer} onClick={() => this.setState({ selectedCustomer: customer.id })}>
                  <span className="h3">{customer.name}</span><br />
                  服务态度要求：{attributes[customer.attitude]}<br />
                  颜值要求：{attributes[customer.charisma]}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
          <Col xs={12} className="mb-3">
            <h2>房间</h2>
            <ButtonGroup>
              {this.state.rooms.map((room) => (
                <Button variant="danger" key={room.id} active={room.id === this.state.selectedRoom} onClick={() => this.setState({ selectedRoom: room.id })}>
                  <span className="h3">{room.name}</span><br />
                  {room.description}
                </Button>
              ))}
            </ButtonGroup>
          </Col>
        </Row>
        {currentCharacter && currentCustomer && (
          <Row>
            <Col>
              <h2>计算结果</h2>
              <p className="h1">
                小费：
                {
                  (currentCharacter.attitude >= currentCustomer.attitude
                    && currentCharacter.charisma >= currentCustomer.charisma)
                    ? (this.state.characterSkill ? currentCharacter.skilledTips : currentCharacter.baseTips)
                    : 0
                }
              </p>
            </Col>
          </Row>
        )}
      </Container >
    );
  }
}

export default MyGames;

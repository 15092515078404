import React from 'react';
import { Container } from 'react-bootstrap';

const DMIntro = () => (
  <Container className="mb-3 mt-2">
    <h2>大纲</h2>
    <p>
      剧本杀目的、心态：不要一味推凶<br />
      时间久：四小时以上<br />
      不要跳车！不要跳车！不要跳车！<br />
      每个剧本一辈子只能玩一次，要投入<br />
      还原故事<br />
      我是主持人，流程我来管就好<br />
      发本：不要超前读本<br />
      读本：不要交流、不要看别人的本<br />
      不要自爆！不要自爆！不要自爆！<br />
      任务：推进剧情的作用，不需要全部完成<br />
      不要贴脸！不要贴脸！不要贴脸！<br />
      词汇：避免 - 我看看本；可以说 - 我回忆一下<br />
      所有人都可以编故事<br />
      总结：充分带入你的角色<br />
      祝玩的愉快、有一个好的体验！
    </p>
    <h2>详细版</h2>
    <p>
      是否是第一次玩？<br />
      不是：好的滚吧。<br />
      是：<br />
      线下跟线上、狼人杀不同，不要用狼人杀的心态 - 推凶不是剧本杀的最主要目的</p>
      <p>特点：<br />
        时间久，4小时以上<br />
          跳车，钱照收 :P<br />
        每个剧本这辈子只能玩一次<br />
          希望认真一些，投入一些<br />
            比如，玩手机，小红书，玩游戏，睡觉<br />
            你们要是全睡觉了我们可开心了，钱照赚</p>
            <p>目的：还原故事，不是一味的找凶手<br />
        对任何事情都八卦一点，多聊聊天<br />
        聊着聊着可能认了哥哥</p>
        <p>我是你们主持人，你们不用管流程，这是我的职责<br />
        你们千万不要聊着聊着自己组队开始去搜证了或者开始投票了啥的</p>
        <p>发本<br />
        不要马上翻开<br />
        两种本：分幕、通读<br />
        不要因为好奇去看之后的内容<br />
        很多剧情都是进阶发展的，比如你这个人物到了第三幕成了凶手，你如果一开始就看到了你是凶手这回事，前两幕你可能就崩溃了</p>
        <p>读本<br />
        不要交流<br />
        千万不要互相看本<br />
        有任何问题私下来找主持人</p>
        <p>个人任务<br />
        凶手不能自爆！你要是自爆凶手了，几个小时的本被你五分钟就玩完了，这趟车是你请客么？反正作为店家我们很开心，五分钟赚完了几个小时的钱 :)<br />
        除了这个任务之外其它的所有任务，不是为了让你完成它们的。任务的意义是告诉你们这步是做什么的<br />
        如果剧本结束了你跟我说主持人我们所有的任务都完成了，那你们这个本就是盘了个寂寞</p>
        <p>避免词汇：本里没写，我看看本里怎么写的<br />
      你可以说：我想想，我回忆一下。<br />
      你可以编，不只是凶手可以编</p>
      <p>贴脸</p>
      <p>最主要的就是一句话：充分带入你的角色，做、想这个角色会做的事情就好了<br />
      【爸爸保护儿子的例子】
    </p>
  </Container>
);

export default DMIntro;

import { createStore } from 'redux';

const initState = {
  userName: '',
  loginStatus: typeof localStorage.getItem('jbsUserAuthorizationToken') === 'string' && !!localStorage.getItem('jbsUserAuthorizationToken'),
};

const JBSDataReducer = (state = initState, action) => {
  let newState = initState;
  switch (action.type) {
    case 'LOGIN':
      newState = {
        ...state,
        userName: action.userName,
        loginStatus: true,
      };
      break;
    case 'LOGOUT':
      localStorage.removeItem('jbsUserAuthorizationToken');
      newState = {
        ...state,
        userName: '',
        loginStatus: false,
      };
      break;
    default:
      break;
  }
  return newState;
};

export default createStore(JBSDataReducer);

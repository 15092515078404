import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Form, FormGroup, Card, Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import ActionButton from '../../shared/fragments/ActionButton';

import api from '../../services/api';

class Register extends React.PureComponent {
  constructor() {
    super();
    this.register = this.register.bind(this);
    this.state = {
      email: '',
      emailError: false,
      name: '',
      nameError: false,
      password: '',
      passwordError: false,
      confirm: '',
      passwordConfirmError: false,
      passwordConfirmMatchError: false,
      accountRegistered: false,
    };
  }

  register(e) {
    e.preventDefault();
    let errorExists = false;
    this.setState({
      emailError: false,
      nameError: false,
      passwordError: false,
      passwordConfirmError: false,
      passwordConfirmMatchError: false,
    });

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.state.email || !emailRegex.test(this.state.email)) {
      this.setState({ emailError: true });
      errorExists = true;
    }
    if (!this.state.name) {
      this.setState({ nameError: true });
      errorExists = true;
    }
    if (!this.state.password) {
      this.setState({ passwordError: true });
      errorExists = true;
    }
    if (!this.state.confirm) {
      this.setState({ passwordConfirmError: true });
      errorExists = true;
    }
    if (this.state.password !== this.state.confirm) {
      this.setState({ passwordConfirmMatchError: true });
      errorExists = true;
    }

    if (!errorExists) {
      return api.post('/account/register', {
        email: this.state.email,
        name: this.state.name,
        password: this.state.password,
      }).then(([account]) => {
        toast.success(`恭喜，${this.state.name}！你的剧本杀小本本账号已经成功注册。`);
        // Remember login session token
        localStorage.setItem('traderCreedAuthorizationToken', account.authKey);
        this.setState({ accountRegistered: true });
      });
    }

    return Promise.reject();
  }

  render() {
    if (this.state.accountRegistered) {
      return (
        <Container>
          <Card>
            <Card.Body className="text-center">
              <h3>{`${this.state.name}，`}</h3>
              <p className="text-success">你的剧本杀小本本账号已成功注册。</p>
              <hr />
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <Button className="cta" onClick={() => this.props.setLoginStatus(this.state.name)} href="/myGames">移步我的打本记录</Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      );
    }
    return (
      <Container>
        <h4 className="text-center mb-3">剧本杀小本本账号注册</h4>
        <Row>
          <Col sm={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
            <Card>
              <Card.Body>
                <Form onSubmit={this.register}>
                  <FormGroup className="mb-3">
                    <Form.Label>邮箱地址</Form.Label>
                    <Form.Control
                      type="email"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      isInvalid={this.state.emailError}
                    />
                    <Form.Control.Feedback type="invalid">请输入一个合理的邮箱地址。</Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Form.Label>名字</Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      isInvalid={this.state.nameError}
                    />
                    <Form.Control.Feedback type="invalid">请取个名字吧。</Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Form.Label>密码</Form.Label>
                    <Form.Control
                      type="password"
                      value={this.state.password}
                      onChange={(e) => this.setState({ password: e.target.value })}
                      isInvalid={this.state.passwordError}
                    />
                    <Form.Control.Feedback type="invalid">请输入一个够强的密码。</Form.Control.Feedback>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Form.Label>确认密码</Form.Label>
                    <Form.Control
                      type="password"
                      value={this.state.confirm}
                      onChange={(e) => this.setState({ confirm: e.target.value })}
                      isInvalid={this.state.passwordConfirmError || this.state.passwordConfirmMatchError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.state.passwordConfirmError && '请确认密码。'}
                      {this.state.passwordConfirmMatchError && '两遍密码输入不相符。'}
                    </Form.Control.Feedback>
                  </FormGroup>
                  <p className="text-center">
                    <ActionButton
                      submit
                      buttonLabel="注册"
                      action={this.register}
                    />
                  </p>
                  <p className="text-center">
                    <Link to="/login">已经有账号了？</Link>
                  </p>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

Register.propTypes = {
  setLoginStatus: PropTypes.func.isRequired,
};

// Map TCData from Redux to this component
const mapStateToProps = (state) => state;

// Map TCData dispatch methods
const mapDispatchToProps = (dispatch) => ({
  setLoginStatus: (userName) => {
    dispatch({
      type: 'LOGIN',
      userName,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);

import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config/default.json';
import JBSData from '../data/JBSData';

const prepareAPICall = () => {
  axios.defaults.headers.common = {
    Authorization: config[process.env.NODE_ENV].authKey,
    JBSUserAuthorization: localStorage.getItem('jbsUserAuthorizationToken'),
  };
  return Promise.resolve();
};

const handleAPIResponse = (APIRequest) => APIRequest.then(
  // Successful API call
  (APIResponse) => {
    if (APIResponse.data.metadata.status === false) {
      toast.warn(`Warning: ${APIResponse.data.metadata.message}`);
      return Promise.reject(APIResponse.data.metadata);
    }
    return Promise.resolve([APIResponse.data.data, APIResponse.data.metadata.pagination]);
  },

  (failure) => {
    const failureResponse = failure.response;
    const errorMessage = failureResponse.data.metadata.message || 'unknown';

    if (failureResponse.status === 500) {
      toast.error(`There's a technical problem with our server. Please try again later. Error: ${errorMessage}`);
      return Promise.resolve([]);
    }

    if (failureResponse.status === 401) {
      toast.error(`Authentication failed: ${errorMessage}`);
      // Set the logout status for the app
      JBSData.dispatch({ type: 'LOGOUT' });
      return Promise.resolve([]);
    }

    if (failureResponse.status === 403) {
      toast.error(errorMessage, { toastId: 403 });
      return Promise.resolve([]);
    }

    toast.error(errorMessage);
    return Promise.resolve([]);
  },
);

class API {
  static async get(URL, params = null) {
    await prepareAPICall();
    return handleAPIResponse(axios.get(config[process.env.NODE_ENV].apiServer + URL, { params }));
  }

  static async post(URL, body) {
    await prepareAPICall();
    return handleAPIResponse(axios.post(config[process.env.NODE_ENV].apiServer + URL, body));
  }
}

export default API;

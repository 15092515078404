import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Form, FormGroup, Card, Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import api from '../../services/api';

import ActionButton from '../../shared/fragments/ActionButton';

class RecoverPassword extends React.PureComponent {
  constructor() {
    super();
    this.resetPassword = this.resetPassword.bind(this);
    this.state = {
      status: '',
      invalidToken: false,
    };
  }

  componentDidMount() {
    // Check for token validation
    if (!this.props.match.params.token) {
      this.setState({ invalidToken: true });
    }

    api.post('/account/verifyPasswordReset', {
      token: this.props.match.params.token,
    }).then(() => null, (error) => {
      toast.error(error);
      this.setState({ invalidToken: true });
    });
  }

  resetPassword(e) {
    e.preventDefault();
    let errorExists = false;
    this.setState({
      passwordError: false,
      passwordConfirmError: false,
      passwordConfirmMatchError: false,
    });

    if (!this.state.confirm) {
      this.setState({ passwordConfirmError: true });
      errorExists = true;
    }
    if (this.state.password !== this.state.confirm) {
      this.setState({ passwordConfirmMatchError: true });
      errorExists = true;
    }

    if (!errorExists) {
      return api.post('/account/resetPassword', {
        token: this.props.match.params.token,
        newPassword: this.state.password,
      }).then(([status]) => {
        this.setState({ status });
      }, (error) => toast.error(error));
    }
    return Promise.reject();
  }

  render() {
    if (this.state.invalidToken) {
      return (
        <Container>
          <h4 className="text-center mb-5">找回密码</h4>
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <Card>
                <Card.Body>
                  <div className="text-center">
                    <p>无效链接</p>
                    <p>
                      <Button href="/login">好吧</Button>
                    </p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <Container>
        <h4 className="text-center mb-5">找回密码</h4>
        <Row>
          <Col sm={{ span: 6, offset: 3 }}>
            <Card>
              <Card.Body>
                {this.state.status.length ? (
                  <div className="text-center">
                    <p>{this.state.status}</p>
                    <p>
                      <Button href="/login">好的</Button>
                    </p>
                  </div>
                ) : (
                  <Form onSubmit={this.resetPassword}>
                    <FormGroup>
                      <Form.Label>新密码</Form.Label>
                      <Form.Control
                        type="password"
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value })}
                        isInvalid={this.state.passwordError}
                      />
                      <Form.Control.Feedback type="invalid">请输入一个够强的密码。</Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup>
                      <Form.Label>确认新密码</Form.Label>
                      <Form.Control
                        type="password"
                        value={this.state.confirm}
                        onChange={(e) => this.setState({ confirm: e.target.value })}
                        isInvalid={this.state.passwordConfirmError || this.state.passwordConfirmMatchError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {this.state.passwordConfirmError && '请确认密码。'}
                        {this.state.passwordConfirmMatchError && '两遍密码输入不相符。'}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <p className="text-center">
                      <ActionButton
                        submit
                        buttonLabel="重设密码"
                        action={this.resetPassword}
                        preDisabled={!this.state.password || !this.state.confirm}
                      />
                    </p>
                    <p className="text-center">
                      <Link to="/login">尝试重新登录</Link>
                    </p>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

RecoverPassword.propTypes = {
  match: PropTypes.shape.isRequired,
};

export default RecoverPassword;

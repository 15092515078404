import React from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import store from './data/JBSData';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import Navigation from './shared/navigation';

import Home from './pages/home';
import Login from './pages/account/login';
import Register from './pages/account/register';
import ForgotPassword from './pages/account/forgotPassword';
import RecoverPassword from './pages/account/recoverPassword';
import Account from './pages/account/account';
import GameList from './pages/game/list';
import GameDetail from './pages/game/detail';
import CharacterDetail from './pages/character/detail';
import MyGames from './pages/myGames';
import DMIntro from './pages/dm/intro';
import ShangZhong from './pages/tools/shangZhong';

class JbsApp extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <div id="app">
          <Navigation />
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/forgotPassword" element={<ForgotPassword />} />
              <Route exact path="/recoverPassword/:token" element={<RecoverPassword />} />
              <Route exact path="/account" element={<Account />} />
              <Route exact path="/games" element={<GameList />} />
              <Route exact path="/gameDetail/:gameId" element={<GameDetail />} />
              <Route exact path="/characterDetail/:characterId" element={<CharacterDetail />} />
              <Route exact path="/myGames" element={<MyGames />} />
              <Route exact path="/dmIntro" element={<DMIntro />} />
              <Route exact path="/tools/shangZhong" element={<ShangZhong />} />
            </Routes>
          </BrowserRouter>
        </div>
        <ToastContainer theme="dark" />
      </Provider>
    );
  }
}

export default JbsApp;

import React from 'react';
import PropTypes from 'prop-types';
// import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Navbar, Nav, Image,
} from 'react-bootstrap';

const Navigation = (props) => {
  // if (!props.loginStatus
  //   && location.pathname !== '/'
  //   && location.pathname !== '/login'
  //   && location.pathname !== '/register'
  //   && location.pathname !== '/forgotPassword'
  //   && !/\/recoverPassword\/.+/.test(location.pathname)) {
  //   return <Navigate to="/login" />;
  // }
  // if (props.loginStatus
  //   && (location.pathname === '/'
  //     || location.pathname === '/login'
  //     || location.pathname === '/register'
  //     || location.pathname === '/forgotPassword'
  //     || /\/recoverPassword\/.+/.test(location.pathname)
  //   )) {
  //   return <Navigate to="/account" />;
  // }

  return (
    <Navbar id="navbar" variant="dark" expand="md">
      <Navbar.Brand href="/">
        <Image id="logoImage" src="/images/logo.png" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          {!props.loginStatus && (
            <>
              <Nav.Link href="/login">登录</Nav.Link>
              <Nav.Link href="/register">注册</Nav.Link>
            </>
          )}
          {props.loginStatus && (
            <>
              <Nav.Link href="/account">账号</Nav.Link>
              <Nav.Link href="/myGames">我的玩本记录</Nav.Link>
              <Nav.Link href="/dmIntro">DM 介绍手册</Nav.Link>
              <Nav.Link href="/games">剧本列表</Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

Navigation.propTypes = {
  loginStatus: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};

// Map JData from Redux to this component
const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(Navigation);

import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Form, FormGroup, Card, Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import api from '../../services/api';

import ActionButton from '../../shared/fragments/ActionButton';

class ForgotPassword extends React.PureComponent {
  constructor() {
    super();
    this.requestPasswordReset = this.requestPasswordReset.bind(this);
    this.state = {
      email: '',
      status: '',
    };
  }

  requestPasswordReset(e) {
    e.preventDefault();
    return api.post('/account/requestPasswordReset', {
      email: this.state.email,
    }).then(([status]) => {
      this.setState({ status });
    }, (error) => toast.error(error));
  }

  render() {
    return (
      <>
        <Container>
          <h4 className="text-center mb-5">忘记密码</h4>
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <Card>
                <Card.Body>
                  {this.state.status.length ? (
                    <div className="text-center">
                      <p>{this.state.status}</p>
                      <p>
                        <Button href="/login">好吧</Button>
                      </p>
                    </div>
                  ) : (
                    <Form onSubmit={this.requestPasswordReset}>
                      <FormGroup>
                        <Form.Label>邮箱地址</Form.Label>
                        <Form.Control
                          type="email"
                          value={this.state.email}
                          onChange={(e) => this.setState({ email: e.target.value })}
                        />
                      </FormGroup>
                      <p className="text-center">
                        <ActionButton
                          submit
                          buttonType="primary"
                          buttonLabel="要求重设密码"
                          action={this.requestPasswordReset}
                          preDisabled={!this.state.email}
                        />
                      </p>
                      <p className="text-center">
                        <Link to="/login">尝试重新登录</Link>
                      </p>
                    </Form>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ForgotPassword;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Container, Row, Col, Form, FormGroup, Card,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import api from '../../services/api';

import ActionButton from '../../shared/fragments/ActionButton';

class Login extends React.PureComponent {
  constructor() {
    super();
    this.login = this.login.bind(this);
    this.state = {
      email: '',
      password: '',
    };
  }

  login(e) {
    e.preventDefault();
    return api.post('/account/login', {
      email: this.state.email,
      password: this.state.password,
    }).then(([user]) => {
      toast.success(`欢迎回来，${user.userName}！`);
      // Remember login session token
      localStorage.setItem('jbsUserAuthorizationToken', user.authKey);
      this.props.setLoginStatus(user.userName);
    });
  }

  render() {
    return (
      <>
        <Container>
          <h4 className="text-center mb-3">登录剧本杀小本本账号</h4>
          <Row>
            <Col sm={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
              <Card>
                <Card.Body>
                  <Form onSubmit={this.login}>
                    <FormGroup className="mb-3">
                      <Form.Label>邮箱地址</Form.Label>
                      <Form.Control
                        type="email"
                        value={this.state.email}
                        onChange={(e) => this.setState({ email: e.target.value })}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Form.Label>密码</Form.Label>
                      <Form.Control
                        type="password"
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value })}
                      />
                      <p className="text-end">
                        <Link to="/forgotPassword">忘记密码？</Link>
                      </p>
                    </FormGroup>
                    <p className="text-center">
                      <ActionButton
                        submit
                        buttonType="primary"
                        buttonLabel="登录"
                        action={this.login}
                        preDisabled={!this.state.email || !this.state.password}
                      />
                    </p>
                    <p className="text-center">
                      <Link to="/register">注册新账号</Link>
                    </p>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

Login.propTypes = {
  setLoginStatus: PropTypes.func.isRequired,
};

// Map TCData from Redux to this component
const mapStateToProps = (state) => state;

// Map TCData dispatch methods
const mapDispatchToProps = (dispatch) => ({
  setLoginStatus: (userName) => {
    dispatch({
      type: 'LOGIN',
      userName,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
